import Button from "@restart/ui/esm/Button";
import { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Image,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { baseConn } from "../services/request";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const TAP_PAY_SDK = "__tappaysdk";
const MySwal = withReactContent(Swal);

function asyncLoadScript(src, id) {
  return new Promise((resolve, reject) => {
    // 防止多次加载
    if (![TAP_PAY_SDK].includes(id) || !src) {
      reject("fail");
      return;
    }

    // 创建元素
    let el = document.createElement("script");
    el.src = src;
    el.id = id;
    // 元素加载完成后执行 onload 方法
    el.onload = function () {
      resolve("success");
    };
    document.head.appendChild(el);
  });
}

function CreditCard() {
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [amount, setAmount] = useState(151);

  useEffect(() => {
    // 异步加载脚本，加载完成后执行 then 方法
    asyncLoadScript(
      "https://js.tappaysdk.com/tpdirect/v5.1.0",
      TAP_PAY_SDK
    ).then((loadStatus) => {
      console.log("loadStatus: ", loadStatus);
      console.log("window.TPDirect: ", window.TPDirect);

      var defaultCardViewStyle = {
        color: "rgb(0,0,0)",
        fontSize: "15px",
        lineHeight: "24px",
        fontWeight: "300",
        errorColor: "red",
        placeholderColor: "",
      };
      console.log("loaded");

      window.TPDirect.setupSDK(
        122603,
        "app_WtPXxyOKVSbNnjEwrXCcl8VUJWpFFWvUeLEwgWkuXZTbmhLTRRIljC2ZHeTH",
        "sandbox"
      );
      window.TPDirect.card.setup("#cardview-container", defaultCardViewStyle, {
        isUsedCcv: true,
      });
      window.TPDirect.card.onUpdate(function (update) {
        var submitButton = document.querySelector("#submitButton");
        var cardViewContainer = document.querySelector("#cardview-container");
        if (update.canGetPrime) {
          submitButton.removeAttribute("disabled");
        } else {
          submitButton.setAttribute("disabled", true);
        }

        if (update.hasError) {
          cardViewContainer.classList.add("error");
        } else {
          cardViewContainer.classList.remove("error");
        }

        if (update.status.number) {
          //showErrorMessage("Please check your credit card number");
        } else {
          //hideErrorMessage();
        }
      });
    });
  }, []);
  const findFormErrors = () => {
    const firstName = document.querySelector("#firstName").value;
    const lastName = document.querySelector("#lastName").value;
    const province = document.querySelector("#province").value;
    const city = document.querySelector("#city").value;
    const street = document.querySelector("#street").value;
    const zipcode = document.querySelector("#zipcode").value;
    const newErrors = {};
    // name errors
    if (!firstName || firstName === "") {
      newErrors.firstName = "Please input your first name";
    } else if (!RegExp("^[A-Za-z ]{0,30}$").test(firstName)) {
      newErrors.firstName = "first name max 30 characters";
    }
    if (!lastName || lastName === "") {
      newErrors.lastName = "Please input your last name";
    } else if (!RegExp("^[A-Za-z ]{0,30}$").test(lastName)) {
      newErrors.lastName = "last name max 30 characters";
    }
    if (!province || province === "") {
      newErrors.province = "Please input your address's province";
    }
    if (!city || city === "") {
      newErrors.city = "Please input your address's city";
    }
    if (!street || street === "") {
      newErrors.street = "Please input your address's detail";
    }
    if (!zipcode || zipcode === "") {
      newErrors.zipcode = "Please input your address's zipcode";
    }
    setErrors(newErrors);
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    const merchant_id = "skybridge_CTBC";
    const currency = "TWD";
    const order_number = "ORDER_123456789";
    const details = "onepay test";
    const phone_number = "+886923456789";
    const email = "hershey@test.org";

    event.preventDefault();
    event.stopPropagation();
    const newErrors = findFormErrors();
    if (form.checkValidity() === true) {
      if (newErrors !== undefined && Object.keys(newErrors).length > 0) {
      } else {
        setValidated(true);
        window.TPDirect.card.getPrime(function (result) {
          if (result.status === 0) {
            var prime = result.card.prime;
            //alert("getPrime success: " + JSON.stringify(prime));

            const firstName = document.querySelector("#firstName").value;
            const lastName = document.querySelector("#lastName").value;
            const postBody = {
              prime: prime,
              merchant_id: merchant_id,
              amount: amount,
              currency: currency,
              order_number: order_number.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
              details: details.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
              cardholder: {
                phone_number: phone_number.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
                name: `${firstName.replace(/&lt;/g, "<").replace(/&gt;/g, ">")} ${lastName.replace(/&lt;/g, "<").replace(/&gt;/g, ">")}`,
                email: email.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
              },
              remember: false,
            };
            baseConn
              .post(
                "/credit-cards/pay-by-prime",
                postBody
              )
              .then((result) => {
                // alert("post result : " + JSON.stringify(result));
                MySwal.fire({
                  text: "You have successfully paid the bills. You will be redirected shortly",
                  icon: "success",
                });
              })
              .catch((ex) => {
                MySwal.fire({
                  html: "Something went wrong. Please contact our customer service.<br /> 發現錯誤。請聯絡我們客服",
                  icon: "error",
                });
              });
          } else {
            // console.log("getPrime error");
          }
        })
      }
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Row>
        <Col xs={4} md={8} lg={3}>
          <Image src="/images/skypay-logo.png" fluid />
        </Col>
        <Col xs={20} md={16} lg={5}>
          <div style={{ float: "right" }}>
            <Image src="/images/cart.png" height="50" />
            <label style={{ fontSize: 20, fontWeight: "bold" }}>${amount}</label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={16} lg={8} className="text-center">
          <label style={{ color: "#ff9600", fontSize: 30, fontWeight: "bold" }}>
            Card
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={16} lg={8}>
          <hr className="solid" style={{ color: "#ff9600", height: 5 }} />
        </Col>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
            Pay with Card
          </Form.Label>
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
          <Col xs={24} md={8} lg={4}>
            <div>
              <div id="cardview-container"></div>
            </div>
          </Col>
        </Form.Group>
        <br />
        <br />
        <Form.Group as={Row} className="mb-3">
          <Col>
            <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
              Billing Address
            </Form.Label>
          </Col>
        </Form.Group>

        <Row>
          <Form.Group
            as={Col}
            className="mb-3"
            controlId="formBasicEmail"
            xs={12}
            md={4}
            lg={2}
          >
            <FloatingLabel label="First Name">
              <Form.Control
                placeholder="First Name"
                name="firstName"
                id="firstName"
                onChange={() => setErrors({ ...errors, firstName: null })}
                isInvalid={!!errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
          <Form.Group
            as={Col}
            className="mb-3"
            controlId="formBasicEmail"
            xs={12}
            md={4}
            lg={2}
          >
            <FloatingLabel label="Last Name">
              <Form.Control
                placeholder="Last Name"
                name="lastName"
                id="lastName"
                onChange={() => setErrors({ ...errors, lastName: null })}
                isInvalid={!!errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Form.Group as={Row} className="mb-3" controlId="formBasicProvince">
          <Col xs={24} md={8} lg={4}>
            <Form.Control
              placeholder="Province"
              name="province"
              id="province"
              onChange={() => setErrors({ ...errors, province: null })}
              isInvalid={!!errors.province}
            />
            <Form.Control.Feedback type="invalid">
              {errors.province}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
          <Col xs={24} md={8} lg={4}>
            <Form.Control
              placeholder="City"
              name="city"
              id="city"
              onChange={() => setErrors({ ...errors, city: null })}
              isInvalid={!!errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {errors.city}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
          <Col xs={24} md={8} lg={4}>
            <Form.Control
              placeholder="# Building, Street, Subdivision"
              name="street"
              id="street"
              onChange={() => setErrors({ ...errors, street: null })}
              isInvalid={!!errors.street}
            />
            <Form.Control.Feedback type="invalid">
              {errors.street}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
          <Col xs={24} md={8} lg={4}>
            <Form.Control
              placeholder="ZIP Code"
              name="zipcode"
              id="zipcode"
              onChange={() => setErrors({ ...errors, zipcode: null })}
              isInvalid={!!errors.zipcode}
            />
            <Form.Control.Feedback type="invalid">
              {errors.zipcode}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col xs={24} md={8} lg={4}>
            <Form.Check
              required
              label="Receive news and offers from OnePay. You can change this setting whenever you want."
              feedback="You must agree before submitting."
              feedbackType="invalid"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col xs={24} md={8} lg={4}>
            <Form.Check
              required
              label="You confirm that you have read, consent and agree
              to OnePay’s OnePay User Agreement and Privacy
              Statement and that you will comply with laws and regulations of The Philippines, including sanctions against payments to North Korea and Iran pursuant to the Foreign Exchange and Foreign Trade Act. If you are 18 to 20 years old, you agree to allow us to contact your parents to confirm that you have their consent to use OnePay."
              feedback="You must agree before submitting."
              feedbackType="invalid"
            />
          </Col>
        </Form.Group>
        <Row>
          <Col xs={24} md={8} lg={4} className="text-center">
            <Button
              type="submit"
              style={{
                border: "none",
                backgroundColor: "transparent",
                marginBottom: 50,
              }}
              id="submitButton"
            >
              <Image src="/images/agree-continue.png" height="40" />
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default CreditCard;
